<template>
  <div class="paymentMethod">
    <mt-header :title="$t('paymentMethod')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="5" />
    <div class="content">
      <div class="navbar">
        <div class="item" :class="{ focus: form.payType == 'BANK' }" @click="form.payType = 'BANK'">{{ $t('bankTransfer') }}</div>
        <!-- <div class="item" :class="{ focus: form.payType == 'CASH' }" @click="form.payType = 'CASH'">{{ $t('cashPickup') }}</div> -->
        <div class="item" :class="{ focus: form.payType == 'OTHER' }" @click="form.payType = 'OTHER'">{{ $t('other') }}</div>
      </div>
      <div v-show="form.payType == 'BANK'">
        <div class="item">
          <div class="title">{{ $t('bankAccountHolder') }}</div>
          <mt-field
            disabled
            :placeholder="$t('bankAccountHolderPlaceholder')"
            :state="bankAccountNameState"
            v-model="form.bankAccountName"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P06_C01_I_BANKACCOUNTNAME',
              newValue: form.bankAccountName
            })"
          ></mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('bank') }}</div>
          <mt-field
            :placeholder="$t('bankPlaceholder')"
            :state="bankCodeState"
            readonly
            v-model="(Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name"
            @click.native="onPopupPicker(Enum.bank, (e) => {
              form.bankCode = e.code;
              BEHAVIOR_ADD({
                id: `P06_C02_S_BANKCODE`,
                newValue: form.bankCode
              })
            })"
          >
            <mt-spinner v-if="Enum.bankLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
          <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('bankAccountNo') }}</div>
          <div class="tips">{{ $t('bankAccountNoTips') }}</div>
          <mt-field
            :placeholder="$t('bankAccountNoPlaceholder')"
            :state="bankCardNoState"
            v-model="form.bankCardNo"
            v-mask="'##############################'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: 'P06_C03_I_BANKCARDNO',
              newValue: form.bankCardNo
            })"
          ></mt-field>
        </div>
      </div>
      <div v-show="form.payType == 'CASH'">
        <div class="item">
          <div class="title">{{ $t('name') }}</div>
          <mt-field
            disabled
            :placeholder="$t('namePlaceholder')"
            v-model="form.bankAccountName"
            :state="bankAccountNameState"
            @blur.native.capture="BEHAVIOR_ADD({
              id: `P06_C04_I_BANKACCOUNTNAME`,
              newValue: form.bankAccountName
            })"
          ></mt-field>
        </div>
        <div class="item">
          <div class="prompt">
            <div class="val">{{ $t('imageOfCompanyId') }}</div>
            <div class="example" @click="$refs.badgeExample.popupVisible = true">{{ $t('example') }}</div>
          </div>
          <div class="image">
            <update-file type="COMPANY_FRONT" @change="onUploadCompanyFront">
              <img src="../../assets/blankBg.png">
            </update-file>
          </div>
          <div class="error">{{ imageOfCompanyIdState == 'error'? $t('imageOfCompanyIdError'): '' }}</div>
          <div class="tip">{{$t('idcardFrontTip')}}</div>
        </div>
        <div class="item">
          <div class="title">{{ $t('institutionName') }}</div>
          <mt-field
            :placeholder="$t('institutionNamePlaceholder')"
            :state="payOrgState"
            readonly
            v-model="(Enum.institution.filter(e => e.code == form.payOrg)[0]||{}).name"
            @click.native="onPopupPicker(Enum.institution, (e) => {
              form.payOrg = e.code;
              BEHAVIOR_ADD({
                id: `P06_C06_S_PAYORG`,
                newValue: form.payOrg
              })
            })"
          >
            <mt-spinner v-if="Enum.institutionLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
            <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item" v-if="form.payFee">
          <div class="title">{{ $t('disbursementHandlingFee') }}</div>
          <mt-field
            disabled
            :placeholder="$t('disbursementHandlingFeePlaceholder')"
            v-model="form.payFee"
            :state="payFeeState"
            @blur.native.capture="BEHAVIOR_ADD({
              id: `P06_C07_I_PAYFEE`,
              newValue: form.payFee
            })"
          ></mt-field>
        </div>
      </div>
      <div v-show="form.payType == 'OTHER'">
        <div class="item">
          <div class="title">{{ $t('channel') }}</div>
          <mt-field
            :placeholder="$t('channelPlaceholder')"
            :state="payChannelState"
            readonly
            v-model="(Enum.payChl.filter(e => e.code == form.payChannel)[0]||{}).name"
            @click.native="onPopupPicker(Enum.payChl, (e) => {
              form.payChannel = e.code;
              BEHAVIOR_ADD({
                id: `P06_C08_S_PAYCHANNEL`,
                newValue: form.payChannel
              })
            })"
          >
            <mt-spinner v-if="Enum.payChlLoading" type="snake" color="#F15234" :size="16"></mt-spinner>
            <i v-else class="iconfont">&#xe62a;</i>
          </mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('name') }}</div>
          <mt-field
            disabled
            :placeholder="$t('namePlaceholder')"
            v-model="form.bankAccountName"
            :state="bankAccountNameState"
            @blur.native.capture="BEHAVIOR_ADD({
              id: `P06_C09_I_BANKACCOUNTNAME`,
              newValue: form.bankAccountName
            })"
          ></mt-field>
        </div>
        <div class="item">
          <div class="title">{{ $t('phoneNumber') }}</div>
          <mt-field
            :placeholder="$t('phoneNumberPlaceholder')"
            v-model="form.payPhone"
            :state="payPhoneState"
            v-mask="'#### #### ##'"
            @blur.native.capture="BEHAVIOR_ADD({
              id: `P06_C11_I_PAYPHONE`,
              newValue: form.payPhone
            })"
          ></mt-field>
        </div>
      </div>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="submit">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
    <badge-example ref="badgeExample" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { applyStep } from '../../utils/api'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import popupPicker from '@/components/popupPicker.vue'
import badgeExample from '@/components/badgeExample.vue'
import updateFile from '@/components/updateFile.vue'
import { nameRules, emptyRules, phoneRules } from '../../utils/rules'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import EXIF from 'exif-js'
import mixin from './mixin'
import gtmPageView from '../../utils/gtm.js'
export default {
  name: 'PaymentMethod',
  mixins: [mixin],
  components: {
    callPhone,
    stepItem,
    popupPicker,
    badgeExample,
    updateFile,
  },
  data () {
    return {
      isCheck: false,
      form: {
        payType: '', // BANK CASH OTHER
        
        bankAccountName: '', // 银行账户持有人
        bankCode: '', // 银行名称
        bankCardNo: '', // 银行账号

        imageId: '', // 公司ID图像
        payOrg: '', // 机构名称
        payFee: '', // 支付手续费

        payChannel: '', // 通道
        payPhone: '', // 电话
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    bankAccountNameState() {
      this.onStorageItem('bankAccountName')
      return this.isCheck ? nameRules(this.form.bankAccountName) : null;
    },
    bankCodeState() {
      this.onStorageItem('bankCode')
      return this.isCheck ? emptyRules(this.form.bankCode) : null;
    },
    bankCardNoState() {
      this.onStorageItem('bankCardNo')
      return this.isCheck ? emptyRules(this.form.bankCardNo) : null;
    },
    imageOfCompanyIdState() {
      this.onStorageItem('imageOfCompanyId')
      return this.isCheck ? emptyRules(this.form.imageId) : null;
    },
    payOrgState() {
      this.onStorageItem('payOrg')
      return this.isCheck ? emptyRules(this.form.payOrg) : null;
    },
    payFeeState() {
      this.onStorageItem('payFee')
      return this.isCheck ? emptyRules(this.form.payFee) : null;
    },

    payChannelState() {
      this.onStorageItem('payChannel')
      return this.isCheck ? emptyRules(this.form.payChannel) : null;
    },
    payPhoneState() {
      this.onStorageItem('payPhone')
      return this.isCheck ? phoneRules(this.form.payPhone) : null;
    },
  },
  watch: {
    'form.payType': {
      handler() {
        this.onStorageItem('payType')
      }
    },
    'form.payOrg': {
      handler() {
        try {
          this.form.payFee = (this.Enum.institution.filter(e => e.code == this.form.payOrg)[0]||{}).attr2
        } catch(e) {
          this.form.payFee = ''
        }
      }
    },
    'Enum.institutionLoading': {
      handler() {
        try {
          this.form.payFee = (this.Enum.institution.filter(e => e.code == this.form.payOrg)[0]||{}).attr2
        } catch(e) {
          this.form.payFee = ''
        }
      }
    }
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      this.form[val] = this.apply[val] || ''
    })
    if (!this.form.payType) {
      this.form.payType = 'BANK'
    }
    let name = [
      this.apply.firstName,
      this.apply.middleName,
      this.apply.lastName,
    ].filter(e => e).join(' ')
    this.form.bankAccountName = this.user.name || name || ''
    try {
      this.form.payFee = (this.Enum.institution.filter(e => e.code == this.form.payOrg)[0]||{}).attr2
    } catch(e) {
      this.form.payFee = ''
    }
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P06_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P06_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_BANK(); // 获取银行
      this.GET_INSTITUTION(); // 获取机构
      this.GET_PAY_CHL(); // 获取通道
    },
    // 上传图像
    onUploadCompanyFront(id, file) {
      this.form.imageId = id
      this.BEHAVIOR_ADD({
        id: `P06_C05_I_ID`,
        newValue: id
      })
      let self = this
      EXIF.getData(file.origin, function() {
        let data = EXIF.getAllTags(this)
        if (data && data.MakerNote && data.MakerNote.length > 100) {
          data.MakerNote = data.MakerNote.slice(0, 100)
        }
        self.BEHAVIOR_ADD({
          id: `P06_C05_E_EXIF`,
          newValue: JSON.stringify(data)
        })
      })
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('bankAccountName') >= 0 && this.bankAccountNameState != "success") {
        return this.$toast(this.bankAccountNameState == "warning"
          ? this.$t("bankAccountHolderPlaceholder")
          : this.$t("bankAccountHolderError"));
      }
      if (list.indexOf('bankCode') >= 0 && this.bankCodeState != "success") {
        return this.$toast(this.bankCodeState == "warning"
          ? this.$t("bankPlaceholder")
          : this.$t("bankError"));
      }
      if (list.indexOf('bankCardNo') >= 0 && this.bankCardNoState != "success") {
        return this.$toast(this.bankCardNoState == "warning"
          ? this.$t("bankAccountNoPlaceholder")
          : this.$t("bankAccountNoError"));
      }
      if (list.indexOf('payChannel') >= 0 && this.payChannelState != "success") {
        return this.$toast(this.payChannelState == "warning"
          ? this.$t("channelPlaceholder")
          : this.$t("channelError"));
      }
      if (list.indexOf('imageOfCompanyId') >= 0 && this.imageOfCompanyIdState != "success") {
        return this.$t("imageOfCompanyIdError");
      }
      if (list.indexOf('payOrg') >= 0 && this.payOrgState != "success") {
        return this.$toast(this.payOrgState == "warning"
          ? this.$t("institutionNamePlaceholder")
          : this.$t("institutionNameError"));
      }
      if (list.indexOf('payFee') >= 0 && this.payFeeState != "success") {
        return this.$toast(this.payFeeState == "warning"
          ? this.$t("disbursementHandlingFeePlaceholder")
          : this.$t("disbursementHandlingFeeError"));
      }
      if (list.indexOf('payPhone') >= 0 && this.payPhoneState != "success") {
        return this.$toast(this.payPhoneState == "warning"
          ? this.$t("phoneNumberPlaceholder")
          : this.$t("phoneNumberError"));
      }
    },
    async submit() {
      this.isCheck = true;
      var error;
      if (this.form.payType == 'BANK') {
        error = this.validation(['bankAccountName', 'bankCode', 'bankCardNo']);
      } else if (this.form.payType == 'CASH') {
        error = this.validation(['bankAccountName', 'imageOfCompanyId', 'payOrg', 'payFee']);
      } else if (this.form.payType == 'OTHER') {
        error = this.validation(['payChannel', 'bankAccountName', 'payPhone']);
      }
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('08_PAYMENT_METHOD', { content_name: '08_PAYMENT_METHOD' })
        }
        // Google Analytics  PAYMENT_METHOD
        if (localStorage['ga']) {
          window.gtag('event', 'PAYMENT_METHOD')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        currentStep: 6,
        totalSteps: 8,

        bankName: (this.Enum.bank.filter(e => e.code == this.form.bankCode)[0]||{}).name,

        applyId: this.user.applyId,
        ...this.form,
        payPhone: this.form.payPhone.replace(/[^\d]/g, '')
      }
      await this.$axios({
        method: "post",
        url: applyStep,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      this.$NProgress.done()
      branch.logEvent('08_PAYMENT_METHOD', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.paymentMethod {
  background-color: #FBFBFF;
  min-height: 100vh;
  .content {
    padding: 10px;
    .navbar {
      display: flex;
      border-radius: 45px;
      background-color: white;
      .item {
        flex: 1;
        text-align: center;
        height: 45px;
        color: #939393;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 45px;
        &.focus {
          background-color: #F15234;
          font-weight: bold;
          color: white;
        }
      }
    }
    .item {
      margin-top: 20px;
      .mint-cell {
        background: white;
        /deep/ .mint-cell-wrapper {
          background: none;
        }
      }
      .prompt {
        position: relative;
        font-size: 16px;
        color: #1D1D1D;
        margin: 15px 6px 40px 6px;
        .val {
          &::after {
            content: '*';
            font-size: 14px;
            vertical-align: top;
            color: #E92121;
          }
        }
        .example {
          position: absolute;
          right: 0px;
          top: 0px;
          text-decoration: underline;
          font-weight: 500;
          font-size: 16px;
          color: #239DE8;
        }
      }
      .image {
        margin: 0 100px;
      }
      .tip {
        font-size: 12px;
        color: #ADB0B6;
        font-size: 15px;
        text-align: center;
      }
      .title {
        margin: 6px 15px;
        font-size: 16px;
        color: #1D1D1D;
        &::after {
          content: '*';
          font-size: 14px;
          vertical-align: top;
          color: #E92121;
        }
      }
      .tips {
        font-size: 13px;
        color: #239DE8;
        margin: 0px 15px 5px 15px;
      }
      .mint-field {
        border-radius: 6px;
        min-height: 46px;
      }
    }
  }
  .error {
    font-size: 12px;
    color: red;
    font-size: 14px;
    text-align: center;
  }
  .iconfont {
    margin: 0 5px;
    color: #F15234;
  }
  .bottom {
    padding: 40px 10px;
    .mint-button {
      border-radius: 6px;
    }
  }
}
</style>
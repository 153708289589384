var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paymentMethod"},[_c('mt-header',{attrs:{"title":_vm.$t('paymentMethod')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{on:{"click":function($event){return _vm.$router.back(-1)}}},[_c('img',{attrs:{"slot":"icon","src":require("@/assets/back.png"),"height":"20","width":"20"},slot:"icon"}),_vm._v(" "+_vm._s(_vm.$t('back'))+" ")])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":5}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"navbar"},[_c('div',{staticClass:"item",class:{ focus: _vm.form.payType == 'BANK' },on:{"click":function($event){_vm.form.payType = 'BANK'}}},[_vm._v(_vm._s(_vm.$t('bankTransfer')))]),_c('div',{staticClass:"item",class:{ focus: _vm.form.payType == 'OTHER' },on:{"click":function($event){_vm.form.payType = 'OTHER'}}},[_vm._v(_vm._s(_vm.$t('other')))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.payType == 'BANK'),expression:"form.payType == 'BANK'"}]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('bankAccountHolder')))]),_c('mt-field',{attrs:{"disabled":"","placeholder":_vm.$t('bankAccountHolderPlaceholder'),"state":_vm.bankAccountNameState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P06_C01_I_BANKACCOUNTNAME',
            newValue: _vm.form.bankAccountName
          })}},model:{value:(_vm.form.bankAccountName),callback:function ($$v) {_vm.$set(_vm.form, "bankAccountName", $$v)},expression:"form.bankAccountName"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('bank')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('bankPlaceholder'),"state":_vm.bankCodeState,"readonly":""},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.bank, (e) => {
            _vm.form.bankCode = e.code;
            _vm.BEHAVIOR_ADD({
              id: `P06_C02_S_BANKCODE`,
              newValue: _vm.form.bankCode
            })
          })}},model:{value:((_vm.Enum.bank.filter(e => e.code == _vm.form.bankCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.bank.filter(e => e.code == _vm.form.bankCode)[0]||{}), "name", $$v)},expression:"(Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name"}},[(_vm.Enum.bankLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('bankAccountNo')))]),_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.$t('bankAccountNoTips')))]),_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('##############################'),expression:"'##############################'"}],attrs:{"placeholder":_vm.$t('bankAccountNoPlaceholder'),"state":_vm.bankCardNoState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: 'P06_C03_I_BANKCARDNO',
            newValue: _vm.form.bankCardNo
          })}},model:{value:(_vm.form.bankCardNo),callback:function ($$v) {_vm.$set(_vm.form, "bankCardNo", $$v)},expression:"form.bankCardNo"}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.payType == 'CASH'),expression:"form.payType == 'CASH'"}]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('name')))]),_c('mt-field',{attrs:{"disabled":"","placeholder":_vm.$t('namePlaceholder'),"state":_vm.bankAccountNameState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: `P06_C04_I_BANKACCOUNTNAME`,
            newValue: _vm.form.bankAccountName
          })}},model:{value:(_vm.form.bankAccountName),callback:function ($$v) {_vm.$set(_vm.form, "bankAccountName", $$v)},expression:"form.bankAccountName"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"prompt"},[_c('div',{staticClass:"val"},[_vm._v(_vm._s(_vm.$t('imageOfCompanyId')))]),_c('div',{staticClass:"example",on:{"click":function($event){_vm.$refs.badgeExample.popupVisible = true}}},[_vm._v(_vm._s(_vm.$t('example')))])]),_c('div',{staticClass:"image"},[_c('update-file',{attrs:{"type":"COMPANY_FRONT"},on:{"change":_vm.onUploadCompanyFront}},[_c('img',{attrs:{"src":require("../../assets/blankBg.png")}})])],1),_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.imageOfCompanyIdState == 'error'? _vm.$t('imageOfCompanyIdError'): ''))]),_c('div',{staticClass:"tip"},[_vm._v(_vm._s(_vm.$t('idcardFrontTip')))])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('institutionName')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('institutionNamePlaceholder'),"state":_vm.payOrgState,"readonly":""},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.institution, (e) => {
            _vm.form.payOrg = e.code;
            _vm.BEHAVIOR_ADD({
              id: `P06_C06_S_PAYORG`,
              newValue: _vm.form.payOrg
            })
          })}},model:{value:((_vm.Enum.institution.filter(e => e.code == _vm.form.payOrg)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.institution.filter(e => e.code == _vm.form.payOrg)[0]||{}), "name", $$v)},expression:"(Enum.institution.filter(e => e.code == form.payOrg)[0]||{}).name"}},[(_vm.Enum.institutionLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),(_vm.form.payFee)?_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('disbursementHandlingFee')))]),_c('mt-field',{attrs:{"disabled":"","placeholder":_vm.$t('disbursementHandlingFeePlaceholder'),"state":_vm.payFeeState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: `P06_C07_I_PAYFEE`,
            newValue: _vm.form.payFee
          })}},model:{value:(_vm.form.payFee),callback:function ($$v) {_vm.$set(_vm.form, "payFee", $$v)},expression:"form.payFee"}})],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.payType == 'OTHER'),expression:"form.payType == 'OTHER'"}]},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('channel')))]),_c('mt-field',{attrs:{"placeholder":_vm.$t('channelPlaceholder'),"state":_vm.payChannelState,"readonly":""},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.payChl, (e) => {
            _vm.form.payChannel = e.code;
            _vm.BEHAVIOR_ADD({
              id: `P06_C08_S_PAYCHANNEL`,
              newValue: _vm.form.payChannel
            })
          })}},model:{value:((_vm.Enum.payChl.filter(e => e.code == _vm.form.payChannel)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.payChl.filter(e => e.code == _vm.form.payChannel)[0]||{}), "name", $$v)},expression:"(Enum.payChl.filter(e => e.code == form.payChannel)[0]||{}).name"}},[(_vm.Enum.payChlLoading)?_c('mt-spinner',{attrs:{"type":"snake","color":"#F15234","size":16}}):_c('i',{staticClass:"iconfont"},[_vm._v("")])],1)],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('name')))]),_c('mt-field',{attrs:{"disabled":"","placeholder":_vm.$t('namePlaceholder'),"state":_vm.bankAccountNameState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: `P06_C09_I_BANKACCOUNTNAME`,
            newValue: _vm.form.bankAccountName
          })}},model:{value:(_vm.form.bankAccountName),callback:function ($$v) {_vm.$set(_vm.form, "bankAccountName", $$v)},expression:"form.bankAccountName"}})],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('phoneNumber')))]),_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### ##'),expression:"'#### #### ##'"}],attrs:{"placeholder":_vm.$t('phoneNumberPlaceholder'),"state":_vm.payPhoneState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
            id: `P06_C11_I_PAYPHONE`,
            newValue: _vm.form.payPhone
          })}},model:{value:(_vm.form.payPhone),callback:function ($$v) {_vm.$set(_vm.form, "payPhone", $$v)},expression:"form.payPhone"}})],1)])]),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"}),_c('badge-example',{ref:"badgeExample"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }